<template>
    <div>
         <Topbar></Topbar>
         <div class="pc-title">首页>商城中心>我的订单>退款管理>管理详情</div>
         <div class="redund-content">
             <div class="buzhou">
                    <div
                    class="buzhou-item"
                    v-for="(item, index) in buzhoulist"
                    :key="index"
                    >
                        <div class="buzhou-item-left">
                                <div class="buzhou-item-img">
                                <img :src="index >= details.RefundStatus+2?item.img:item.img2" alt="" />
                                </div>
                            <!-- 需要根据状态换图片 暂时更换字体颜色 -->
                                <div
                                class="buzhou-item-font"
                                :style="{ color: index == details.RefundStatus+1 ? 'red' : '#000' }"
                                >
                                {{ item.text }}
                                </div>
                        </div>
                    <div :class="[index>=details.RefundStatus+1 ? ' line2' : ' line']" v-if="index != buzhoulist.length - 1" ></div>
                    </div>
           </div>





        <div class="status">
        <div class="status-top">
          <div class="status-icon">
            <img src="../../../assets/images/redgantan.png" alt="" />
          </div>
          <div class="status-font" v-if="details.RefundStatus == 0">
            当前状态：进行中
          </div>
          <div class="status-font" v-else-if="details.RefundStatus== 1">
            当前状态：已完成
          </div>
         
        </div>
        <div class="status-button">
          <div class="esc" v-if="details.RefundStatus == 0" @click="handleCancel()">
            取消售后
          </div>
        </div>
       
      </div>
       <!-- 售后信息 -->
        <div class="infor">
            <div class="infor-title">售后信息</div>
            
            <div class="infor-detail-item">
                <div class="infor-detail">
             <div class="people ">
              退款原因：{{details.RefundReason}}
            </div>  
                <div class="address mr">申请时间:{{ details.CreateTime }}</div>
                <div class="num mr">退款编号:{{ details.RefundCode }}</div>
                 <div class="addpay mr" >
                    <div>退款总金额:</div> 
                    <div class="addpay-money">{{details.ApplyRefundMoney}}</div>
                </div>
                <div class="mr">退款说明:{{details.RefundRemark}}</div>
                <div class="mr">退款方式:{{ details.RefundMoneyChannel==0
                ? "仅退款"
                : details.RefundStatus == 1
                ? "退款退货"
                : details.RefundStatus == 2
                ? "换货"
                :''}}</div>

                <div class="shijipay mr"  >
                <div>退回{{ details.RefundMoneyChannel== 0
                ? "普利点"
                : details.RefundStatus == 1
                ? "支付宝"
                : details.RefundStatus == 2
                ? "微信"
                : details.RefundStatus == 3
                ? "银行卡"
                : details.RefundStatus == 4
                ? "现金":''}}:</div>
            <div class="shijiapay-money">￥{{ details.ApplyPoint}}</div> 
                </div>
            </div>
            </div>
      </div>

     <!-- shangping -->
     <div class="goods">
          <div class="infor-title">
            <div class="infor-title-item" v-for="(item, index) in titlelist" :key="index">
                <div>{{ item.text }}</div>
            </div>
          </div>
           <div class="goods" >
        <div
          class="goods-item"
          v-for="(goods, j) in detail.OrderDetailList"
          :key="j"
        >
          <div class="content-left-img">
            <img :src="goods.SpecImg" alt="" />
          </div>
          <div class="content-left-font">
            <div class="font1">{{ goods.GoodsName }}</div>
            <div class="font2">{{ goods.SpecName }}</div>
          </div>
          <div class="content-price">￥{{ goods.GoodsPrice }}</div>
          <div class="content-num">{{ goods.GoodsCount }}</div>
          <div class="off">-¥{{ detail.DiscountMoney }}</div>
        </div>
    </div>
    </div>
     
         </div>
    </div>
</template>
<script>
import { queryApplyRefundDetail,deleteShopRefund,queryShopOrderDetail } from "@/api/shop";
import Topbar from "../../PCshop/components/topbar.vue";
export default {
    components:{
        Topbar
    },
    data(){
        return{
          detail:[],
          titlelist: [
        {
          text: "商品"
        },
        // {
        //   text: "商品编号"
        // },
        {
          text: "价格"
        },
        {
          text: "商品数量"
        },
        {
          text: "优惠"
        },
      ],
              route: {},
              details: {},
              buzhoulist: [
                    {
                    text: "1.提交申请",
                    img: require("../../../assets/ShopImg/one.png"),
                    img2: require("../../../assets/ShopImg/one.png"),
                    },
                    {
                    text: "2.进行中",
                    img: require("../../../assets/ShopImg/two.png"),
                    img2: require("../../../assets/ShopImg/two.png"),
                    },
                    {
                    text: "3.已完成",
                    img: require("../../../assets/ShopImg/therr1.png"),
                    img2: require("../../../assets/ShopImg/therr2.png"),
                    }
            ],
             titlelist: [
                        {
                        text: "商品"
                        },
                        {
                        text: "商品编号"
                        },
                        {
                        text: "价格"
                        },
                        {
                        text: "商品数量"
                        },
                        {
                        text: "优惠"
                        }
                ],
        }
     
    },
    created() {
    this.route = this.$route.query;
    console.log(this.$route.query)
    this.init()
    this.getQueryShopOrderDetail()
  },
  methods:{
       async init(){
            let parm = "?id=" + this.route.id;
            const res = await queryApplyRefundDetail(parm);
            this.details = res.response;
            console.log(this.details,888)
        },
       // 取消
    async handleCancel() {
      const res = await deleteShopRefund(this.route.id);
      if (res.success==true){
        this.$message.success(res.msg);
         this.$router.push({
          path: "/PCshop/my/shopmy",
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    // 订单详情
     async getQueryShopOrderDetail(){
      const res = await queryShopOrderDetail(this.route.orderid);
      this.detail = res.response;
      console.log(this.detail,5555)
      if(this.status==2){
         this.postQueryShopLogisticsList(this.orderId)
      }
      if(this.detail.OrderDetailList[0].IsEvaluation==1){
        console.log('lalala')
        this.detail.Status=5
      }
    },
  }
}
</script>
<style lang='scss' scoped>
  .pc-title{
        width: 1100px;
        height: 55px;
        margin: 0 auto;
        line-height: 55px;
        color: #666666;
        font-size: 12px;
    }
   
    .redund-content{
        width: 1200px;
        // height: 800px;
        background: #FFFFFF;
        margin:  0 auto;
        overflow: hidden;
    }
    .buzhou {
         margin-top: 20px;
        .buzhou-item-left {
            width: 70px;
        }
        margin-top: 30px;
        display: flex;
        padding-left: 95px;
        .buzhou-item {
            text-align: center;
            display: flex;
            .buzhou-item-font {
            margin-top: 25px;
            }
        }
        .line {
            width: 400px;
            height: 4px;
            background: #f95e2e;
            margin-top: 12px;
        }
        .line2{
            width:400px;
            height: 4px;
            background: #EEEEEE;
            margin-top: 12px;
        }
}
.status {
  .status-top {
    display: flex;
  }
  width: 96%;
  margin: 39px auto 21px;
  border: 1px solid #efefef;
  height: 136px;
  padding-top: 20px;
  padding-left: 20px;
  .status-icon {
    width: 18px;
    height: 18px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .status-font {
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
  }
  .status-two {
    margin-top: 13px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    .status-time {
      color: #f40707;
    }
  }
  .status-button {
    display: flex;
    margin-top: 16px;
    .pay {
      width: 80px;
      height: 30px;
      background: #f95e2e;
      color: #ffffff;
      font-size: 12px;
      text-align: center;
      line-height: 30px;
    }
    .esc {
      width: 80px;
      height: 30px;
      border: 1px solid #e8e7e7;
      text-align: center;
      line-height: 30px;
      margin-left: 20px;
    }
  }
}
.addpay{
    display: flex;
    
}
.shijipay{
    display: flex;
}
.addpay-money , .shijiapay-money{
    margin-left:4px;
    color:#f40707;
    font-size: 15px;
}
.infor {
  background: #ffffff;
  border: 1px solid #efefef;
  width: 96%;
  margin: 20px auto 21px;
  .infor-title {
    padding-top: 15px;
    padding-left: 20px;
    height: 50px;
    color: #999999;
    font-size: 14px;
    background: #efefef;
  }
  .infor-detail {
    width: 100%;
    font-size: 14px;
    padding-top: 19px;
    padding-left: 20px;
    box-sizing: border-box;
    .price {
      display: flex;
      .money {
        color: #f40707;
      }
    }
    .mr {
      margin-top: 5px;
    }
  }
}

.goods {
  width: 96%;
  margin: 20px auto 21px;
  .infor-title {
    height: 50px;
    color: #333333;
    font-size: 14px;
    background: #efefef;
    display: flex;
    line-height: 50px;
  }
  .goods-item {
    display: flex;
    border-bottom: 1px solid #e9e9e9;
    padding-left: 21px;
    padding-top: 25px;
    padding-bottom: 20px;
    .content-left-img {
      width: 60px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .content-left-font {
      max-width: 308px;
      margin-left: 14px;
      .font1 {
        width: 308px;
        height: 38px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .font2 {
        font-size: 12px;
        color: #999999;
        margin-top: 7px;
      }
    }
    .number {
      margin-left: 98px;
    }
    .content-price {
      margin-left: 35px;
    }
    .content-num {
      margin-left: 106px;
    }
    .off {
      margin-left: 107px;
    }
    .pj{
        width: 80px;
        height: 30px;
        border: 1px solid #e9e9e9;
        text-align: center;
        line-height: 30px;
        margin-left: 200px;
        margin-top: -4px;
    }
    .wuliu{
        margin-left: 112px;
    }
    .pay {
      margin-top: -6px;
      margin-left: 58px;
      width: 80px;
      height: 30px;
      background: #f95e2e;
      color: #ffffff;
      font-size: 12px;
      text-align: center;
      line-height: 30px;
    }
  }
}
.infor-title-item:nth-child(1){
      min-width:400px ;
      text-align: center;
  }
  .infor-title-item:nth-child(2){
      min-width:172px ;
      text-align: center;
  }
  .infor-title-item:nth-child(3){
      min-width:91px ;
      text-align: center;
  }
  .infor-title-item:nth-child(4){
      min-width:146px ;
      text-align: center;
  }
  .infor-title-item:nth-child(5){
      min-width:129px ;
      text-align: center;
  }
</style>